import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import { RetellWebClient } from "retell-client-js-sdk";
import { DeepChat } from "deep-chat-react";

const agentId = "d1265aad3ac2451992bee2064973c378";

interface ChatMessage {
  text: string;
  role: "user" | "ai";
  timestamp?: number;
}

interface Word {
  word: string;
  start: number;
  end: number;
}

interface TranscriptMessage {
  role: "agent" | "user";
  content: string;
  words: Word[];
  metadata?: {
    response_id: number;
  };
}

interface Update {
  event_type: string;
  transcript: TranscriptMessage[];
}

interface RegisterCallResponse {
  call_id?: string;
  sampleRate: number;
}

interface EntityLabels {
  Age: string;
  Gender: string;
  Location: {
    Country: string;
    State: string;
    City: string;
  };
  Income: string;
  EducationLevel: string;
  MaritalStatus: string;
  Occupation: string;
  BrandPreference: string;
  ProductPreference: string;
  PriceSensitivity: string;
  FavoriteProductCategories: string;
  PreferredShippingMethod: string;
  PreferredCommunicationChannel: string;
  LifestylePreferences: string;
  InterestsAndHobbies: string;
  ValuesAndBeliefs: string;
}

const defaultLabels: EntityLabels = {
  Age: "N/A",
  Gender: "N/A",
  Location: {
    Country: "N/A",
    State: "N/A",
    City: "N/A",
  },
  Income: "N/A",
  EducationLevel: "N/A",
  MaritalStatus: "N/A",
  Occupation: "N/A",
  BrandPreference: "N/A",
  ProductPreference: "N/A",
  PriceSensitivity: "N/A",
  FavoriteProductCategories: "N/A",
  PreferredShippingMethod: "N/A",
  PreferredCommunicationChannel: "N/A",
  LifestylePreferences: "N/A",
  InterestsAndHobbies: "N/A",
  ValuesAndBeliefs: "N/A",
};

const EntityTable: React.FC<{ entities: EntityLabels }> = ({ entities }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Label</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(entities).map(([label, value]) => (
          <tr key={label}>
            <td>{label}</td>
            <td>
              {typeof value === "object"
                ? Object.entries(value)
                    .map(([subLabel, subValue]) => `${subLabel}: ${subValue}`)
                    .join(", ")
                : value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// Create a new Web Worker
const worker = new Worker(new URL('./entityWorker.ts', import.meta.url));

const App: React.FC = () => {
  const [isCalling, setIsCalling] = useState(false);
  const [entities, setEntities] = useState<EntityLabels>(defaultLabels);
  const deepChatRef = useRef<typeof DeepChat>(null);
  const webClient = useRef(new RetellWebClient());
  const transcriptRef = useRef<string>('');

  useEffect(() => {
    // Setup Web Worker message handler
    worker.onmessage = (event: MessageEvent) => {
      if (event.data.entities) {
        setEntities(event.data.entities);
      }
    };

    // Setup event listeners
    webClient.current.on("conversationStarted", () => {
      console.log("conversationStarted");
    });

    webClient.current.on("audio", (audio: Uint8Array) => {
      console.log("There is audio");
    });

    webClient.current.on("conversationEnded", ({ code, reason }: { code: number; reason: string }) => {
      console.log("Closed with code:", code, ", reason:", reason);
      setIsCalling(false);
    });

    webClient.current.on("error", (error: Error) => {
      console.error("An error occurred:", error);
      setIsCalling(false);
    });

    webClient.current.on("update", (update: Update) => {
      console.log("update.event_type = ", update.event_type);
      (deepChatRef.current as any).disableSubmitButton();
      console.log("update", update);

      if (update.event_type === "update" && update.transcript) {
        (deepChatRef.current as any).clearMessages();

        update.transcript.forEach((message) => {
          const newMessage: ChatMessage = {
            text: message.content,
            role: message.role === "agent" ? "ai" : "user"
          };

          if (deepChatRef.current) {
            (deepChatRef.current as any).addMessage(newMessage, true);
            (deepChatRef.current as any).scrollToBottom();
          }
        });

        // Update the transcript reference
        transcriptRef.current = update.transcript
          .map((message) => message.role + ": " + message.content)
          .join(' ');
      }
    });

    // Set up periodic entity extraction
    const extractionInterval = setInterval(() => {
      if (transcriptRef.current) {
        worker.postMessage({ text: transcriptRef.current });
      }
    }, 10000); // Run every 10 seconds

    // Cleanup function
    return () => {
      worker.terminate();
      clearInterval(extractionInterval);
    };
  }, []);

  const toggleConversation = async () => {
    if (isCalling) {
      webClient.current.stopConversation();
    } else {
      const registerCallResponse = await registerCall(agentId);
      if (registerCallResponse.call_id) {
        webClient.current
          .startConversation({
            callId: registerCallResponse.call_id,
            sampleRate: registerCallResponse.sampleRate,
            enableUpdate: true,
          })
          .catch(console.error);
        setIsCalling(true);
      }
    }
  };

  async function registerCall(agentId: string): Promise<RegisterCallResponse> {
    try {
      const response = await fetch(
        "https://anyreachapi.callyour.ai/register-call-on-your-server",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            agent_id: agentId,
          }),
        },
      );
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      const data: RegisterCallResponse = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={toggleConversation}>
          {isCalling ? "Stop" : "Start"}
        </button>
        <DeepChat
          ref={deepChatRef}
          demo={true}
          history={[]}
        />
        <EntityTable entities={entities} />
      </header>
    </div>
  );
};

export default App;